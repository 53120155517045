import React, { useEffect, useState, useMemo } from "react";
import {excelParams, GET, PGET} from "../../../api/adminApi";
import PropTypes from "prop-types";
import { Select, Row, Col } from "antd";
import moment from "moment";
import Table from "../../ui/Table";
import {moneyFormatter} from "../../../utils/utilities";
import DatePicker from "react-datepicker";
import {CustomSvg} from "../../../utils/CustomSvg";

export const TableChart = ({ options, height, layout }) => {
    const [dashboardData, setDashboardData] = useState([]);
    const [dateRange, setDateRange] = useState([
        new Date('2023-01-01'),
        new Date('2025-01-01'),
    ]);
    const [startDate, endDate] = dateRange;
    const [investment, setInvestment] = useState({ id: 0, name: "Все" });
    const [investments, setInvestments] = useState([investment]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(5);
    async function getInvestments() {
        try {
            const response = await GET("/services/admin/api/investment-helper");
            if (response?.length > 0) {
                setInvestments([investment, ...response]);
            }
        } catch (error) {
            console.error('Failed to fetch investments', error);
        }
    }

    const handleChangeInvestment = (value) => {
        const selectedInvestment = investments.find(inv => inv.id === value);
        setInvestment(selectedInvestment);
    };
    function weekOwnerAgents() {
        const excelUrl = '/services/admin/api/dashboard-month-counts/download-all';
        const fileName = 'income-stats.xlsx'
        excelParams(excelUrl, fileName, true, {
            investmentId: investment.id,
            dateFrom: moment(startDate).format("YYYY-MM-DD"),
            dateTo: moment(endDate).add(1, 'day').format("YYYY-MM-DD"),
        });
    }
    async function getDashboardMonthCounts() {
        try {
            if (dateRange[0] && dateRange[1]) {
                const data = await PGET("/services/admin/api/dashboard-month-counts/", true, {
                    investmentId: investment.id,
                    dateFrom: moment(startDate).format("YYYY-MM-DD"),
                    dateTo: moment(endDate).add(1, 'day').format("YYYY-MM-DD"),
                });
                if (data) {
                    setDashboardData(data.data);
                }
            }
        } catch (error) {
            console.error('Failed to fetch dashboard data', error);
        }
    }

    useEffect(() => {
        getInvestments();
    }, []);

    useEffect(() => {
        getDashboardMonthCounts();
    }, [investment, startDate, endDate]);

    const columns = [
        {
            title: "№",
            key: "id",
            dataIndex: "id",
            width: "5%",
            align: "center",
            render: (id, item, index) => (
                <div className="text-center">
                    {(page - 1) * 5 + index + 1}
                </div>
            ),
        },
        {
            title: "Дата",
            key: "month",
            dataIndex: "month",
            width: 50,
            align: "right",
            render: (item) => (
                <div className="text-right" >
                    {item}
                </div>
            ),
        },
        {
            title: "Оплаты",
            key: "totalPayment",
            dataIndex: "totalPayment",
            align: "right",
            width: 100,
            render: (item) => (
                <div className="text-right" >
                    {moneyFormatter(item)}
                </div>
            ),
        },

        {
            title: "Прибыль",
            key: "totalProfit",
            dataIndex: "totalProfit",
            align: "right",
            width: 100,
            render: (item) => (
                <div className="text-right" key={item}>
                    {moneyFormatter(item)}
                </div>
            ),
        },
        {
            title: "Прибыль в процентах",
            key: "profitOnPercent",
            dataIndex: "profitOnPercent",
            align: "right",
            width: 100,
            render: (item) => (
                <div className="text-right" key={item}>
                    {moneyFormatter(item)}%
                </div>
            ),
        },
    ]
    return (
        <div style={{padding: '20px', width: '100%'}}>
                <Row gutter={[16, 16]} justify="end" className="mb-5">
                    <Col xs={24} sm={12} md={8} lg={6}>
                        <Select
                            style={{width: '100%'}}
                            value={investment.id}
                            onChange={handleChangeInvestment}
                        >
                            {investments.map(inv => (
                                <Select.Option key={inv.id} value={inv.id}>
                                    {inv.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6}>
                        <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                setDateRange(update);
                            }}
                            isClearable={true}
                            className="datepicker-custom-input"
                        />
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={3}>
                        <button
                            onClick={weekOwnerAgents}
                            className="mr-0 box-shadow"
                            style={{
                                backgroundColor: "#fcfcfc",
                                height: 32,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                border: "1px solid rgb(212, 211, 214)",
                                borderRadius: 5,
                                padding: "0 10px",
                                width: 150,
                            }}
                        >
                            <CustomSvg path='/icons/duotune/files/fil017.svg' className='svg-icon-3 mr-2'/>
                            Скачать все
                        </button>
                    </Col>
                </Row>
            <Table
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
                data={dashboardData}
                totalElements={dashboardData.length}
                // onRow={rowClick}
                // isLoading={isLoading || isFetching}
                columns={columns}
            />
        </div>
    );
};

TableChart.defaultProps = {
    options: {
        maintainAspectRatio: true,
        responsive: true,
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
            },
        },
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: true,
            },
        },
    },
};

TableChart.propTypes = {
    height: PropTypes.number,
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(PropTypes.object),
    options: PropTypes.object,
    layout: PropTypes.object,
};
